<template>
  <v-dialog v-model="dialog" width="450">
    <v-card>
      <v-card-title>
        {{ data.name }}
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-select :items="partners" item-text="Company" item-value="ID" label="Партньори" v-model="data.partner"
                    return-object clearable>

          </v-select>
          <v-select :items="goodGroups" label="Група на услуги" v-model="data.group_id" item-value="ID" item-text="Name"
                    clearable>

          </v-select>
          <v-select
              :items="userLevels"
              label="Роля"
              v-model="data.userLevel"
              item-value="value"
              item-text="name"
             >

          </v-select>
          <v-switch v-model="data.is_active"
                    :label="data.is_active?'Деактивирай профила':'Активирай профила'"></v-switch>
          <section v-if="user.userLevel === 2">
            <v-subheader>Допълнителни данни</v-subheader>
            <v-divider></v-divider>
            <v-text-field label="Име" v-model="data.name" class="mt-5" :rules="[v => !!v || 'Името е задължително']">

            </v-text-field>

            <v-text-field label="Email" v-model="data.email" :rules="[
        v => !!v || 'E-mail адресът е задължителен',
        v => /.+@.+\..+/.test(v) || 'E-mail адресът трябва да бъде валиден',
      ]">

            </v-text-field>
            <v-text-field label="Хотел" v-model="data.hotel" class="mt-5" :rules="[v => !!v || 'Хотелът е задължителен']">

            </v-text-field>
          </section>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" text @click="cancel">
          Отказ
        </v-btn>
        <v-btn color="green" text @click="confirm">
          Запази
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "EditPartner",
  data: () => ({
    dialog: false,
    data: {},
    resolve: null,
    reject: null,
    scale: false,
    valid:true,
    partners: [],
    goodGroups: [],
    userLevels: [
      {
        name: 'Клиент',
        value: 0,
      },
      {
        name: 'Управител',
        value: 1,
      },
      {
        name: 'Администратор',
        value: 2,
      }
    ]
  }),
  computed: {
    user: {
      get() {
        return this.$store.state.user
      }
    }
  },
  methods: {
    open(data) {
      this.dialog = true
      this.data = data;
      this.fetchPartners();
      this.fetchGoodGroups();
      return new Promise(((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      }))

    },
    confirm() {
      let v= this.$refs.form.validate();
      if(v){
        this.dialog = false
        this.resolve(this.data)
      }

    },
    cancel() {
      this.dialog = false
      this.resolve(false)
    },
    fetchPartners() {
      let self = this;
      this.get('/partners/get').then(r => {
        self.partners = r.partners
      })
    },
    fetchGoodGroups() {
      let self = this;
      this.get('/goods/groups').then(r => {
        self.goodGroups = r.services
      })
    }
  },
}
</script>

<style scoped>

</style>